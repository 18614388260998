.container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
}

@media (max-width:1024px) {
	.container {
        flex-direction: column;
    }
    
    .label {
        width: 100%!important;
        padding-bottom: 16px;
    }

    .arrow {
        display: none;
    }
}

.label {
    width: 40%;
    text-align: left;
    color: white;
    font-size: 18px;
    font-weight: 500;
}

.arrow {
    color: white;
    font-size: 36px;
    padding: 0px 48px;
}

.action {
    min-width: 320px;
    margin-top: 0px!important;
    padding: 12px 24px;
    border-radius: 24px;
    background: var(--marketplaceColorLight);

    text-decoration: none;
    text-align: center;

    &:hover {
        background: var(--colorWhite);
    }

    @media (max-width: 1024px) {
        min-width: auto;
    }
}

a {
    color: white;
    text-decoration: none!important;
}

.primary {
    background-image: linear-gradient(
        42deg,
        #2473b4 0%,
        #32b5ee 100%
    );
}

.secondary {
    background-image: linear-gradient(
        42deg,
        #d8ab56 0%,
        #f0d28b 100%
    );
}

.secondary:hover {
    color: #d8ab56 !important;
}