.container {
    background: #b2d6e9;
    border-radius: 24px;
    padding: 2px 18px 2px 4px;
    flex: 0 0 auto;

    @media (max-width:1024px) {
        padding: 2px 8px 2px 2px;
    }
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    white-space: nowrap;
    overflow: hidden;
}

.row img {
    border-radius: 50%;
}

.icon {
    width: 28px;
    height: 28px;
    margin: 2px;
}

.title {
    color: black!important;
    font-size: 18px;
    font-weight: 400;
    margin-left: 8px;
    padding: 0;

    @media (max-width:1024px) {
        font-size: 14px;
        margin-left: 4px;
    }
}

.primary {
    background: #c5e9f9;
}

.secondary {
    background: #f9efc5;
}