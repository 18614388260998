@import '../../../../styles/customMediaQueries.css';

.container {
  width: var(--contentMaxWidthPages);
  margin: 0 auto;
  padding: 0 32px 64px 32px;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent!important;
}

.listings {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  position: relative;
}

.listings a {
  color: var(--marketplaceColor);
}

.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
