@import '../../../../styles/customMediaQueries.css';

.container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    @media (max-width: 1024px) {
        display: flex;
        flex-direction: column;
    }
}

.col{
    display: flex;
    flex-direction: column;
}

.icon {
    max-width: 96px;
    max-height: 96px;
    margin: 16px 64px 0 32px;

    @media (max-width: 1024px) {
        margin: 16px 64px 0 0px;
    }
}

.title {
    color: white!important;
    font-size: 24px;
}

.content {
    color: white!important;
    font-size: 16px;
    opacity: 0.5;
    line-height: 180%;
    max-width: 100ch;
}
