@import '../../../../styles/customMediaQueries.css';

.container {
    display: flex;
    flex-direction: row;
    padding-left: 32px;
    
    @media (max-width: 1024px) {
        display: flex;
        flex-direction: column;
        padding-left: 0;
    }
}

.col{
    display: flex;
    flex-direction: column;
}

.title {
    color: var(--marketplaceColor)!important;
    font-size: 18px;
    font-weight: bold;
}

.blank {
    @media (max-width: 1024px) {
        display: none;
    }
}

.content {
    color: black!important;
    font-size: 18px;
    opacity: 0.5;
    max-width: 100ch;
    margin-top: 0;
    padding-top: 0;
}
