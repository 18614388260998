@import '../../../../styles/customMediaQueries.css';

.container {
  padding-bottom: 0;
}

.chips {
  max-width: var(--contentMaxWidthPages);
  margin: 0 auto;
  margin-top: 24px;
  padding: 12px 32px 0 32px;

  display: flex;
  flex-wrap: wrap;
  row-gap: 12px;
  column-gap: 8px;
  
  @media (max-width: 1024px) {
    max-width: none;
  }
}