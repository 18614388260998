@import '../../../../styles/customMediaQueries.css';

.row {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  margin-top: 32px;
  max-width: var(--contentMaxWidthPages);

  justify-content: space-between;

  @media (max-width: 1024px) {
    max-width: none;
    padding: 0 32px;
    display: block;
  }
}

.container { 
  flex: 0 0 80%;
  min-width: 60%;
}

.icon {
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  max-width: 256px;

  @media (max-width: 1024px) {
    display: none;
  }
}

.icon img {
  max-width: 100%;
  height: auto;
  object-fit: contain; /* Ensures the image scales proportionally */
}

.footer { 
  width: max-content;
  margin-top: 24px;
  margin-left: 96px;
  padding: 4px 4px;
  border-radius: 32px;
  background:  #c5e9f9;
  
  text-decoration: none;
  text-align: center;
  
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  
  @media (max-width: 768px) {
    flex-direction: column;
    row-gap: 8px;
    background: none;

    margin: 0 auto;
    margin-top: 24px;
  }
}

.footerAction {
  margin-top: 0px!important;
  padding: 12px 24px;
  border-radius: 32px;
  background:  #c5e9f9;

  color: black;
  text-decoration: none;
  text-align: center;
}

.primary {
  color: white;
  background-image: linear-gradient(
      42deg,
      #2473b4 0%,
      #32b5ee 100%
  );
}

.secondary {
  color: white;
  background-image: linear-gradient(
      42deg,
      #d8ab56 0%,
      #f0d28b 100%
  );
}
